import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

import Image from "./Image";
import ViewCounter from "./ViewCounter";


const CardBlogHorizontal = ({ cardData, className, style }) => (
  <Link
    to={`/blog/${cardData.slug}`}
    style={style || {}}
    className={`card card--common card--blog card--blog--horizontal align-items-center ${className ||
      ""}`}
  >
    <div className="px-0 px-md-2 col-lg-6">
      <Image
        className="my-auto card-img-top"
        image={{ gatsbyImageData: cardData.cardImage.gatsbyImageData, src: cardData.cardImage.url }}
        alt={cardData.title}
      />
    </div>
    <div className="card-body col-lg-6">
      <div className="card--blog__parameters">
        <span>{cardData.category.name}</span>
        <div className="badge badge-light">
          <ViewCounter id={cardData.slug} isCard /> views
        </div>
      </div>
      <h3 className="pt-3 pt-lg-6">{cardData.title}</h3>
      <p className="flex-grow-1 mb-0">{cardData.cardDescription}</p>
    </div>
  </Link>
);

CardBlogHorizontal.propTypes = {
  cardData: PropTypes.object.isRequired,
};

export default CardBlogHorizontal;
