const formattedBuildDate = (buildDate) => {
    if (!(buildDate instanceof Date)) {
        buildDate = new Date(buildDate);
    }
    return buildDate.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    });
};

const currentYear = new Date().getFullYear();

// Function to replace variables in text:
const replaceVariables = (buildDate, text) => {
    if (typeof text !== 'string') {
        return text; // Return the input as-is if it's not a string
    }
    return text
        .replace(/\${currentDate}/g, formattedBuildDate(buildDate))
        .replace(/\${currentYear}/g, currentYear);
};

const replaceVariablesInObject = (buildDate, obj) => {
    if (typeof obj === 'string') {
        return replaceVariables(buildDate, obj);
    }
    if (Array.isArray(obj)) {
        return obj.map(item => replaceVariablesInObject(buildDate, item));
    }
    if (typeof obj === 'object' && obj !== null) {
        return Object.fromEntries(
            Object.entries(obj).map(([key, value]) => [
                key,
                replaceVariablesInObject(buildDate, value),
            ])
        );
    }
    return obj;
};

export { replaceVariables, replaceVariablesInObject };
