import React from "react";
import useScript from "../hooks/useScript"
//import { useMixpanelTracker } from "../hooks/useMixpanelTracker";

// import NewsletterImage1 from '../images/photos/newsletter_image_1.png';

const NewsletterForm = () => {
 /*  useMixpanelTracker("7", (mixpanel, formData) => {
    mixpanel.alias(formData["email"]);
    mixpanel.identify(formData["email"]);

    mixpanel.track("Newsletter form submission", {
      Email: formData["email"],
    });

    mixpanel.people.set({
      $email: formData["email"],
    });
  }); */

  return (
    <div id="activecampaign-form">
      <div id="activecampaign-form__wrapper">
        <h4 className="h2 text-center mb-2">
          Free Exclusive UX Articles & Strategies
        </h4>
        <p className="lead text-center mb-4">
          for Startups, UX Designers & Entrepreneurs
        </p>
        {/*<img src={NewsletterImage1} class="newsletter_image" alt="" />*/}
        <div id="activecampaign-form">
          <div id="activecampaign-form__wrapper">
            <div className=" _form_7"></div>
            <p className="text-center">
              <br />
              Sent 1x week. You can unsubscribe any time.
            </p>
          </div>
        </div>

        {useScript("https://adamfard48700.activehosted.com/f/embed.php?id=7")}
      </div>
    </div>
  );
};

export default NewsletterForm;
