import React from "react";
import { Helmet } from "react-helmet";

export const AboutUsJsonLd = () => {
  const mainjson = {
    "@context": "http://schema.org",
    "@type": "AboutPage",
    url: "https://adamfard.com/about-us",
    name: "We Are an Agile UX Design Agency",
    mentions: [
      "https://adamfard.com/about-us",
      {
        "@type": "creativework",
        "@id": "https://adamfard.com/ontology/Application#thecurtainexchange",
        name: "About Adam Fard UX Studio - Agile UX Design Agency"
      }
    ],
    about: {
      "@type": "Organization",
      sameAs: [
        "https://www.linkedin.com/company/adam-fard/",
        "https://twitter.com/adamfard_",
        "https://medium.com/@adam.fard/",
        "https://dribbble.com/adamfardstudio",
        "https://www.behance.net/AdamFardStudio/"
      ],
      url: "https://adamfard.com/about-us",
      logo:
        "https://adamfard.com/static/c3ead8266a0ba7e8c3e2d7638a3b4941/58556/ux-design-agency-3.webp",
      description:
        "We’re a distributed and remote team of UX and UI Designers, UX Researchers & Marketers.",
      parentOrganization: "https://adamfard.com/",
      name: "Adarm Fard",
      "@id": "https://adamfard.com/"
    },
    "@id": "https://adamfard.com/contact-us"
  };

  const webpageJson = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "@id": "https://adamfard.com/about-us/#Webpage",
    headline: "About Adam Fard UX Studio - Agile UX Design Agency",
    url: "https://adamfard.com/about-us",
    primaryImageOfPage: [
      "https://adamfard.com/static/c3ead8266a0ba7e8c3e2d7638a3b4941/58556/ux-design-agency-3.webp",
      "https://www.datocms-assets.com/16499/1604481816-thinkpricing-thumbnail-fintech-b.png",
      "https://www.datocms-assets.com/16499/1594154933-qolo-thumbnail-fintech.png",
      "https://adamfard.com/static/1dfdf503a4abd611e0e52930a44a021a/58556/what_we_achieved.webp",
      "https://adamfard.com/static/5632cb4024f31fb365ebb0dab989c67a/58556/what_we_do.webp",
      "https://adamfard.com/static/work-remotely-db70ebede063eac1c03bd9b4bf0743f6.webp"
    ],
    lastReviewed: "2021/02/10",
    relatedLink: ["https://en.wikipedia.org/wiki/User_interface_design"],
    significantLink: "https://adamfard.com/contact-us",
    description:
      "UX Agency: We are an agile UX Design Studio, composed of a team of UX Designers, Researchers & Marketers",
    image: "https://www.datocms-assets.com/16499/1578152525-dtappux.png",
    sameAs: ["https://en.wikipedia.org/wiki/User_interface"],
    about: [
      {
        "@type": "thing",
        name: "User interface design",
        sameAs: ["https://en.wikipedia.org/wiki/User_interface_design"]
      },
      {
        "@type": "thing",
        name: "Usability testing",
        sameAs: "https://en.wikipedia.org/wiki/Usability_testing"
      },
      {
        "@type": "thing",
        name: "User experience design",
        sameAs: "https://en.wikipedia.org/wiki/User_experience_design"
      }
    ]
  };

  const breadCrumbJson = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    numberOfitems: "2",
    description:
      "UX Agency: We are an agile UX Design Studio, composed of a team of UX Designers, Researchers & Marketers",
    disambiguatingDescription:
      "Each of us brings invaluable skills, passion, and experience to our work. This has helped us double the in-app purchases of a Global 500 company by understanding user behavior and reducing the cognitive load of their app. Our UI UX Agency also helped an app become featured by implementing a new design system and overhauling existing UX patterns. This led to 10M+ downloads and a 4.7+ average user rating.",
    mainEntityOfPage: "https://adamfard.com/about-us",
    image:
      "https://www.datocms-assets.com/16499/1573910711-morecastux.png?auto=format&dpr=0.77&w=680",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        item: { "@id": "https://adamfard.com/", name: "Adam Fard" }
      },
      {
        "@type": "ListItem",
        position: 2,
        item: { "@id": "https://adamfard.com/about-us", name: "About Us" }
      }
    ]
  };

  return (
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(mainjson)}</script>
      <script type="application/ld+json">{JSON.stringify(webpageJson)}</script>
      <script type="application/ld+json">
        {JSON.stringify(breadCrumbJson)}
      </script>
    </Helmet>
  );
};
