import React, { Component } from "react";
import { Link, graphql } from "gatsby";
import Image from "../components/Image";
import Layout from "../components/layout";
import CardBlog from "../components/CardBlog";
import CardBlogHorizontal from "../components/CardBlogHorizontal";
// import CardBlogLarge from "../components/CardBlogLarge";
import NewsletterForm from "../components/NewsletterForm";

import LogoModus from "../images/logos/brand/modus.svg";
import LogoUXCollective from "../images/logos/brand/uxcollective.svg";
import LogoPrototypr from "../images/logos/brand/prototypr.svg";
import LogoHackernoon from "../images/logos/brand/hackernoon.svg";
import LogoTNW from "../images/logos/brand/tnw.svg";
import LogoSM from "../images/logos/brand/smashing-magazine.svg";

import SvgDivider2 from "../images/dividers/divider-2.svg";
import DecorationBlob19 from "../images/decorations/deco-blob-19.svg";
import { AboutUsJsonLd } from "../components/jsonld/AboutUsJsonLd";
import { replaceVariables } from "../utils/replaceDate";

const CurrentYear = new Date().getFullYear();

class UxDesignBlogPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: "all"
    };
  }

  setNewActiveTab = tabName => {
    this.setState({
      activeTab: tabName
    });
  };

  render() {
    const {
      data,
      location: { pathname }
    } = this.props;
    const FeaturedArticlesData = data.featuredArticles.edges;
    const AllArticlesData = data.allArticles.edges;
    const CategoriesData = data.allDatoCmsBlogCategory.edges;
    const buildDate = data.buildDate;

    const { activeTab } = this.state;

    return (
      <Layout
        isHeaderDark={true}
        headerBgClass="bg-primary-3"
        meta={{ seo: data.datoCmsPage.seo, path: pathname }}
      >
        <AboutUsJsonLd />
        <div className="ux-resources-page">
          <section className="bg-primary-3 has-divider text-light o-hidden">
            <div className="container layer-2 py-3">
              <div className="row">
                <div className="col-12 col-lg-8 text-left">
                  <h1 className="display-4 py-1">UX Design Blog</h1>
                  <p className="">
                    On User Experience, Interface & Product Design: Series of
                    articles, strategies and best practices dedicated to
                    Startups, Entrepreneurs & Designers.
                  </p>
                </div>

                <div className="col-12 mt-3 text-left row align-items-center publications-section">
                  <p className="col-12 col-sm-auto lead">Featured in</p>
                  <div className="col-12 col-lg-10 mb-0 align-items-center">
                    <ul className="d-flex publications-list mb-0 align-items-center list-unstyled">
                      
                      <li>
                        <a href="https://www.smashingmagazine.com/2021/02/building-user-trust-in-ux-design/" target="_blank">
                          <LogoSM className="mb-2" />
                        </a>
                      </li>
                      
                      <li>
                        <a href="https://uxdesign.cc/why-should-product-teams-use-wireframes-more-often-60e34a2bc55" target="_blank">
                          <LogoUXCollective className="mb-2" />
                        </a>
                      </li>
                      
                      <li>
                        <a href="https://blog.prototypr.io/design-thinking-demystified-a-manual-for-innovation-66925ad1f86a" target="_blank">
                          <LogoPrototypr className="mb-2" />
                        </a>
                      </li>
                      
                      <li>
                        <a href="https://thenextweb.com/news/a-z-guide-to-launching-your-digital-product" target="_blank">
                          <LogoTNW className="mb-2" />
                        </a>
                      </li>
                      {/* <li>
                        <LogoModus className="mb-2" />
                      </li> */}
                      
                      <li>
                        <a href="https://hackernoon.com/u/adamfard" target="_blank">
                          <LogoHackernoon className="mb-2" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/*<div className="divider">
              <SvgDivider1 />
            </div>*/}
          </section>

          <section className="featured-section has-divider o-hidden layer-4">
            <div className="decoration-wrapper">
              <div
                className="decoration deco-blog-bg"
                style={{ top: -390, overflowX: "hidden", width: "100%" }}
              >
                <DecorationBlob19
                  className="bg-primary-alt flip-y flip-x"
                  style={{ width: "100%" }}
                />
              </div>
            </div>

            <div id="newsletter-card" className="container pt-5">
              <div className="row">
                <div className="col-12">
                  <div className="mb-2">
                    <h2 className="h1">Selected Stories</h2>
                  </div>
                </div>

                {/*<div className="col-12">
                  {FeaturedArticlesData.slice(0, 1).map(({ node }) => (
                    <CardBlogLarge key={node.id} cardData={node} />
                  ))}
                </div>*/}

                {/* {[
                  {
                    node: {
                      title: "Hire a UX Designer: Guide to Find, Interview and Recruit",
                      cardDescription:
                        "Use this complete guide to hire a UX designer. Learn how to find, interview and recruit a designer with the step by step guide.",
                      cardImage: {
                        url:
                          "https://adamfard.com/static/cover-how-to-hire-ux-designer-df028945650aa486902269496da2fde5.png?auto=format",
                      },
                      category: { name: "UX Design" },
                      id: "hire-ux-designer",
                      slug: "hire-ux-designer",
                    },
                  },
                  ...FeaturedArticlesData,
                ].map(({ node }) => (
                  <div className="col-md-6 col-lg-6" key={node.id}>
                    <CardBlog cardData={node} />
                  </div>
                ))} */}

          
                <div className="col-md-6 col-lg-6 d-flex">
                  <div className="card card--common card-body card--no-hover bg-primary-3">
                    <div className="text-light my-auto">
                      <NewsletterForm />
                    </div>
                  </div>
                </div>  

                {FeaturedArticlesData.map(({ node }) => (
                  <div className="col-md-6 col-lg-6" key={node.id}>
                    <CardBlog 
                        cardData={{...node, 
                          title: replaceVariables(buildDate, node.title),
                          cardDescription: replaceVariables(buildDate, node.cardDescription)
                    }} />
                  </div>
                ))}

                <div className="col-md-6 col-lg-6 d-flex">
                  <Link
                    to={`https://www.youtube.com/watch?v=6apD7d7ERzI`}
                    className="card card--common card--blog card--blog--"
                    target="_blank"
                  >
                    <Image
                      className="card-img-top"
                      image={{ src: 'https://www.datocms-assets.com/16499/1639841600-uxprocessblogyoutube.png' }}
                      alt={`UX Design Process: All You Need to Know (${CurrentYear})`}
                    />
                    <div className="card-body">
                      <div className="card--blog__parameters">
                        <span>YouTube</span>
   
                      </div>
                      <h3>UX Design Process: All You Need to Know ({CurrentYear})</h3>
                      <p className="flex-grow-1 mb-0">In this in-depth video, we'll break down the UX design process from start to finish (or rather from finish to start).</p>
                    </div>
                  </Link>
                </div>
                
              </div>
            </div>
          </section>

          <section className="bg-primary-alt has-divider featured-stories">
            <div className="divider bg-white">
              <SvgDivider2 className="bg-primary-alt" />
            </div>
            <div className="container">
              <div className="row layer-4">
                <div className="col-12 pt-5 pt-md-0">
                  <div className="mb-2">
                    <h2 className="h1 mb-4">Recents Stories</h2>

                    <div className="d-flex mb-5">
                      <ul className="nav custom-tabs custom-tabs--scroll lead">
                        <li
                          className="nav-item"
                          onClick={() => this.setNewActiveTab("all")}
                        >
                          <span
                            className={`nav-link pl-0 ${
                              activeTab === "all" ? "active" : ""
                            }`}
                            style={{ cursor: "pointer" }}
                          >
                            All Topics
                          </span>
                        </li>
                        {CategoriesData.map(({ node }) => (
                          <li
                            className="nav-item"
                            key={node.id}
                            onClick={() => this.setNewActiveTab(node.name)}
                          >
                            <span
                              className={`nav-link pl-0 ${
                                activeTab === node.name ? "active" : ""
                              }`}
                              style={{ cursor: "pointer" }}
                            >
                              {node.name}
                            </span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-md-12 col-lg-12">
                  {activeTab === "all"
                    ? [
                      {
                        node: {
                          title:
                            "Hire a UX Designer: The " + CurrentYear + " Recruitment Guide",
                          cardDescription:
                            "Use this complete guide to hire a UX designer. Learn how to find, interview and recruit a designer with the step by step guide.",
                          cardImage: {
                            url:
                              "https://adamfard.com/static/cover-how-to-hire-ux-designer-c6f2e1e46241766aff21024ad665f808.png?auto=format"
                          },
                          category: { name: "UX Design" },
                          id: "hire-ux-designer",
                          slug: "hire-ux-designer"
                        }
                      },
                      ...AllArticlesData,
                        {
                          node: {
                            title:
                              "Accessibility through the eyes of impaired users. Literally",
                            cardDescription:
                              "15% of all users are impaired. However, there’s no shortage of accessibility fails online. Here’s how it actually feels to see bad designs as a user with a disability.",
                            cardImage: {
                              url:
                                "https://www.datocms-assets.com/16499/1630402670-accessibility-thumbnail.png?auto=format"
                            },
                            category: { name: "UX Design" },
                            id: "accessibility-prototype",
                            slug: "accessibility-prototype"
                          }
                        }
                      ].map(({ node }) => (
                        <CardBlogHorizontal key={node.id} 
                        cardData={{...node, 
                          title: replaceVariables(buildDate, node.title),
                          cardDescription: replaceVariables(buildDate, node.cardDescription)
                        }} 
                        />
                      ))
                    : [
                      {
                        node: {
                          title:
                            "Hire a UX Designer: The ${CurrentYear} Recruitment Guide",
                          cardDescription:
                            "Use this complete guide to hire a UX designer. Learn how to find, interview and recruit a designer with the step by step guide.",
                          cardImage: {
                            url:
                              "https://adamfard.com/static/cover-how-to-hire-ux-designer-c6f2e1e46241766aff21024ad665f808.png?auto=format"
                          },
                          category: { name: "Guides" },
                          id: "hire-ux-designer",
                          slug: "hire-ux-designer"
                        }
                      },
                        ...AllArticlesData,
                        {
                          node: {
                            title:
                              "Accessibility through the eyes of impaired users. Literally",
                            cardDescription:
                              "15% of all users are impaired. However, there’s no shortage of accessibility fails online. Here’s how it actually feels to see bad designs as a user with a disability.",
                            cardImage: {
                              url:
                                "https://www.datocms-assets.com/16499/1630402670-accessibility-thumbnail.png?auto=format"
                            },
                            category: { name: "UX Design" },
                            id: "accessibility-prototype",
                            slug: "accessibility-prototype"
                          }
                        }
                      ]
                        .filter(({ node }) => node.category.name === activeTab)
                        .map(({ node }) => (
                          <CardBlogHorizontal key={node.id} 
                          cardData={{...node, 
                            title: replaceVariables(buildDate, node.title),
                            cardDescription: replaceVariables(buildDate, node.cardDescription)}
                          } />
                        ))}
                </div>

                {/*<div className="col-md-4 col-lg-3">
                  <div className="card card--common card-body card--no-hover sticky-top">
                    <div id="activecampaign-form">
                      <div id="activecampaign-form__wrapper">
                        <NewsletterForm />
                      </div>
                    </div>
                  </div>
                </div>*/}
              </div>
            </div>
          </section>
        </div>
      </Layout>
    );
  }
}

export default UxDesignBlogPage;

export const query = graphql`
  query UxDesignBlogQuery {
    datoCmsPage(slug: { eq: "blog" }) {
      seo {
        title
        description
        twitterCard
        image {
          url
        }
      }
    }

    buildDate: currentBuildDate

    featuredArticles: allDatoCmsArticle(
      limit: 2
      filter: { featured: { eq: true } }
      sort: { fields: position, order: ASC }
    ) {
      edges {
        node {
          title
          cardDescription
          cardImage {
            gatsbyImageData(width: 544,  layout: CONSTRAINED)
          }
          category {
            name
          }
          id
          slug
        }
      }
    }

    allArticles: allDatoCmsArticle(sort: { fields: date, order: DESC }) {
      edges {
        node {
          title
          cardDescription
          cardImage {
            gatsbyImageData(layout: CONSTRAINED)
          }
          category {
            name
          }
          id
          slug
        }
      }
    }

    allDatoCmsBlogCategory(sort: { fields: order, order: ASC }) {
      edges {
        node {
          id
          name
          order
        }
      }
    }
  }
`;
